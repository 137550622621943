import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Router, { useRouter } from 'next/router';
import findIndex from 'lodash/findIndex';
import LoadImage from './LoadImage';
import InlineLoader from './InlineLoader';
import getTransformedImageURLs from '../helpers/getTransformedImageURLs';
import emptyCartPopup from '../public/static/lotties/EmptyCart.json';
import { SCHEDULE, ASAP, ASAP_AND_SCHEDULE } from '../constants/deliveryType';

import { useModifyCart } from '../hooks/cart';
// import appConfig from '../appConfig';
import appContext from '../Context/appContext';
import Price from './Price';
import BundlePrice from './BundlePrice';
import BuyXGetYOffersList from './BuyXGetYOffersList';
import notificationTypes from '../constants/modalNotificationConst';
import * as WEBSITES from '../constants/website';
import { useGetTabFromProduct } from '../hooks/Shop';
import getProductType from '../helpers/getProductType';
// import { ASAP, ASAP_AND_SCHEDULE } from '../constants/deliveryType';
import storageService from '../services/storageService';
// import { useDeliveryDetails } from '../hooks/app';
import useAPI from '../NetworkCall/API';
import { useDeliveryDetails } from '../hooks/app';
import { isWishlistApplicable } from '../constants/feature';
import { useDispatch, useSelector } from 'react-redux';
import { increaseURLstack, pushNotification, pushNotificationOverlap } from '../redux/slices/modalSlice';
import { popNotification } from '../redux/slices/modalSlice';
import { setUrlBeforeProduct } from '../redux/slices/userSlice';
import { setAddingToCart, setAllCartProductsWidget } from '../redux/slices/cartSlice';
import { useMemo } from 'react';
import { useConfigData } from '../Context/ConfigProvider';
import { extractDiscountDetails } from '../hooks/volumeDiscountHandler';

const Item = React.memo((
  {
    data: oldData,
    cartItems = [],
    couponDetails,
    hideProductWeight = false,
    // totalFeesIncluded,
    from,
    isSaveForLaterItemsLoading,
    asapEnabled,
    scheduleEnabled,
    isWalletCreditWithPurchaseApplicable,
    DISPLAY_TAXED_PRICE,
    volumeDiscountData
  }) => {
  const dispatchRedux = useDispatch()

  const isLoggedIn = useSelector(state => state.user.isLoggedIn)

  const data = { ...oldData };
  const productDeliveryType = getProductType(data);
  const tab = useGetTabFromProduct(data);
  const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';


  function showProductPage() {
    if (
      [
        WEBSITES.KOAN,
        WEBSITES.DRINKCANN,
        WEBSITES.WUNDER,
        WEBSITES.CANNADIPSTHC,
        WEBSITES.BEED,
        WEBSITES.POTLI,
        WEBSITES.ALTLIFE,
        WEBSITES.HELLOAGAIN,
        WEBSITES.VETCBD,
        WEBSITES.MAISONBLOOM,
        WEBSITES.BOASTCANNABIS,
        WEBSITES.RIGHTRATIO,
        WEBSITES.GETOOKA
      ].includes(process.env.NEXT_PUBLIC_APP)
    ) {
      if (data.bundle_id) {
        Router.push('/bundle/[slug]', `/bundle/${data.slug}?tab=${tab}`);
      } else {
        Router.push('/product/[slug]', `/product/${data.slug}?tab=${tab}`);
      }
    } else {
      dispatchRedux(setAllCartProductsWidget(false))
      let url = '';
      dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: `${window.location.pathname + window.location.search}` }))
      let isBundle = true;
      if (data.bundle_id) {
        url = `/bundle/${data.slug}?tab=${tab}`;
      } else {
        isBundle = false;
        url = `/product/${data.slug}?tab=${tab}`;
      }
      dispatchRedux(popNotification())
      dispatchRedux(pushNotification({ type: notificationTypes.productDetails, data: { slug: data.slug, tab, page: '', isBundle } }))
      dispatchRedux(increaseURLstack())
      window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
    }
  }

  const applyDiscount = price => {
    const { discount = 0, slash_price: slashPrice } = couponDetails;
    const { asap, schedule, menu } = data;
    if (slashPrice) {
      switch (Number(couponDetails.eligible_delivery_type)) {
        case 1:
          if (asap && menu != 2) {
            let discountedAmount = price * ((100 - discount) / 100);
            if (Math.round(discountedAmount) !== discountedAmount) {
              discountedAmount = discountedAmount.toFixed(2);
            }
            return discountedAmount;
          }
          return price;
        case 2:
          if (schedule && menu != 1) {
            let discountedAmount = price * ((100 - discount) / 100);
            if (Math.round(discountedAmount) !== discountedAmount) {
              discountedAmount = discountedAmount.toFixed(2);
            }
            return discountedAmount;
          }
          return price;
        default:
          let discountedAmount = price * ((100 - discount) / 100);
          if (Math.round(discountedAmount) !== discountedAmount) {
            discountedAmount = discountedAmount.toFixed(2);
          }
          return discountedAmount;
      }
    } else {
      return price;
    }
  };

  const weight =
    (data.category_unit || data.product_unit) === '1/8 oz' || (data.category_unit || data.product_unit) === '1/2 oz'
      ? `${data.category_unit || data.product_unit || ''}`
      : `${data.category_weight || data.product_weight || ''} ${data.category_unit || data.product_unit || ''}`;

  const productImage = getTransformedImageURLs(data.image || data.product_image).srcSmall;
  const bulkBasePrice = data && data.bulk_base_price ? data.bulk_base_price : false;
  let orignalPrice = data.price_without_deal ? data.price_without_deal : bulkBasePrice;
  const { discount_code: discountCode, type } = couponDetails;

  // case for category volume pricing
  if (data.offer_category_id && data.category_volume_discounts && !data.is_pre_deal_available && !data.is_deal_available) {
    let quantity = 0;
    cartItems.forEach(cartItem => {
      if (
        cartItem.offer_category_id == data.offer_category_id &&
        !cartItem.is_deal_available &&
        !cartItem.is_pre_deal_available
      ) {
        quantity += cartItem.quantity;
      }
    });
    const discount = (() => {
      const discounts = [...data.category_volume_discounts].sort((a, b) => b.units - a.units);
      let finalDiscount = 0;
      discounts.some(discountIternal => {
        if (quantity >= discountIternal.units) {
          finalDiscount = discountIternal.discount;
          return true;
        }
        return false;
      });
      return finalDiscount;
    })();
    const discountedPrice = (applyDiscount(data.price, couponDetails) * (100 - discount)) / 100;
    orignalPrice = data.price;
    data.price = discountedPrice;
  }

  const getProductTypeLabel = () => {
    return productDeliveryType === ASAP_AND_SCHEDULE ? null : productDeliveryType === ASAP ? 'Super Fast' : 'SCHEDULED';
  };


  return (
    <div className="item mt-2">
      <div className="d-flex relative align-items-center">
        <div className=" mt-1 c-img-container align-self-center">
          <LoadImage
            src={productImage}
            alt={
              data.image_alt_tag && data.image_alt_tag ? data.image_alt_tag : data.name || data.product_name || data.bundle_name
            }
            width={!isCheckoutOnly ? 60 : null}
            height={!isCheckoutOnly ? 60 : null}
          />
        </div>

        <div className="pl-2 pr-0 col d-flex justify-content-between">
          <div>
            <div
              className={`product-name ${isCheckoutOnly ? 'no-pointer' : ''}`}
              role="presentation"
              onClick={!isCheckoutOnly ? showProductPage : null}
            >
              {data.name || data.product_name || data.bundle_name}
            </div>

            <div className="col p-0 ">
              {data?.brand_names?.length ? <div className=" product-brand">{data.brand_names[0]}</div> : null}

              <div className="d-flex mt-1 align-items-center bottom-sec mb-1">
                <div className="price-container">
                  {data.bundle_id ? (
                    <BundlePrice
                      className="price "
                      currency={data.category_currency}
                      orignalPrice={data.original_price}
                      price={data.price}
                    />
                  ) : (
                    <div className='flex items-center gap-2'>
                      <Price
                        // noTaxCalculation={from == "checkout"}
                        productDetails={data}
                        priceClassName=""
                        orignalPrice={orignalPrice}
                        categorySalePriceId={data.category_sale_price_id}
                        volumeDiscountData={Object.keys(volumeDiscountData).includes(data.offer_category_id?.toString()) ? volumeDiscountData[data.offer_category_id?.toString()] : null}
                      />
                    </div>
                  )}
                </div>

                {data.category_weight && data.category_unit && !hideProductWeight ? (
                  <span className="cart-weight">
                    <span className="cart-details-line mx-2">|</span>
                    {`${weight.replace(/(\d+)\s?grams?/g, "$1 g")}`}
                  </span>
                ) : null}

                {data?.concentrated_weight || data?.non_concentrated_weight ? (
                  <span className="cart-weight">
                    <span className="cart-details-line mx-2">|</span>
                    <i className={`fs-16 text-primary mr-1 ${data?.concentrated_weight ? 'icon-conc' : 'icon-non-conc'}`} />
                  </span>
                ) : null}
              </div>

              {asapEnabled && scheduleEnabled ? (
                <small className={`mt-2  ${productDeliveryType === ASAP_AND_SCHEDULE ? ' ' : 'product-label'}`}>
                  {getProductTypeLabel()}
                </small>
              ) : null}
            </div>
          </div>
          <h6></h6>
          <CartItemCounter from={from} isSaveForLaterItemsLoading={isSaveForLaterItemsLoading} data={data} isLoggedIn={isLoggedIn} oldData={oldData} isWalletCreditWithPurchaseApplicable={isWalletCreditWithPurchaseApplicable} />
          {/* <div className="text-center align-self-end mt-2 mb-1 d-flex flex-column">
            <div className="count-holder text-right align-self-end d-flex">
              <>
                {!isCartLocked ? (
                  <>
                    {data.quantity == 1 ? (
                      <button
                        disabled={addToCartLoader || updatingQuantity || (isLoggedIn && !data.cart_id)}
                        type="button"
                        className="btn update-count-btn align-middle delBtn"
                        onClick={handleDelete}
                      >
                        <span className="icon-trash-alt2 delete-btn" />
                      </button>
                    ) : (
                      <button
                        disabled={updatingQuantity || (isLoggedIn && !data.cart_id)}
                        type="button"
                        className="btn update-count-btn align-middle"
                        onClick={handleDecreaseQuantity}
                      >
                        <span>
                          <i className="icon-minus count-btn bold" />
                        </span>
                      </button>
                    )}
                  </>
                ) : null}

                {updatingQuantity && addToCartLoader ? (
                  <InlineLoader positionRelative className="px-3 mb-0 auto-width a-loader" />
                ) : (
                  <span className="count d-inline-block bold align-middle">{data.quantity}</span>
                )}

                {!isCartLocked ? (
                  <button
                    disabled={
                      data.quantity >= maxQuantity ||
                      data.is_pre_deal_available ||
                      updatingQuantity ||
                      (isLoggedIn && !data.cart_id)
                    }
                    type="button"
                    className="btn update-count-btn align-middle pr-0"
                    onClick={handleIncreaseQuantity}
                  >
                    <span>
                      <i className="icon-plus count-btn bold" />
                    </span>
                  </button>
                ) : null}
              </>
            </div>

            <div>
              {isWishlistApplicable ? (
                <div className="mt-1">
                  <a
                    className={`pointer save-for-later-link ${isSaveForLaterItemsLoading ? 'no-pointer-events-rr' : ''}`}
                    onClick={saveForLater}
                  >
                    Save for Later
                  </a>
                </div>
              ) : null}
            </div>
          </div> */}
        </div>
      </div>

      {/* {type == 2 ? renderSaleInfo(isSomeDealAvailable, discountCode) : null} */}
      {data.bundle_id && discountCode && type == 2 && (
        <small className="d-block sale-info ">Coupon code discount not applied to product bundle.</small>
      )}
      {/* {loader && <div className="cart-item-loader inline-loader big" />} */}
      {data?.offer_product?.length ? <BuyXGetYOffersList data={data} showOnlyApplied /> : null}
    </div>
  );
});
const CartWidgetItems = React.memo(({ from }) => {
  const isSaveForLaterItemsLoading = useSelector(state => state.wishlist?.isSaveForLaterItemsLoading)
  const {
    data: { asapEnabled, scheduleEnabled, isWalletCreditWithPurchaseApplicable }
  } = useDeliveryDetails();
  const couponData = useSelector(state => state.user.couponData)
  const { cart_items: cartItems = [], totalFeesIncluded = 0 } = useSelector(state => state.cart?.cartData) || {}
  const appConfig = useConfigData();
  const [volumeDiscountData, setVolumeDiscountData] = useState({})

  useEffect(() => {
    setVolumeDiscountData(cartItems.length ? extractDiscountDetails(cartItems) : {})
  }, [cartItems])
  if (!cartItems?.length) {
    return (
      <>
        <div className="logo-container">
          <img src="/static/images/empty-cart.png" alt="Empty Cart" />

          {/* <Player className="lottie-address" autoplay src={emptyCartPopup} /> */}
        </div>

        <div className="p-3 text-center">
          <h3 className="cat-menu-title">Your bag is empty</h3>

          <p className="md-grey-color">Please add items to your shopping bag. Enjoy shopping with us.</p>
        </div>
      </>
    );
  }
  return (
    <>
      {cartItems.map(cartItem => (
        <Item
          asapEnabled={asapEnabled}
          scheduleEnabled={scheduleEnabled}
          isWalletCreditWithPurchaseApplicable={isWalletCreditWithPurchaseApplicable}

          isSaveForLaterItemsLoading={isSaveForLaterItemsLoading}
          key={cartItem.product_details_id}
          data={cartItem}
          couponDetails={couponData}
          hideProductWeight={appConfig.HIDE_PRODUCT_WEIGHT}
          // cartItems={cartItems}
          // totalFeesIncluded={totalFeesIncluded}
          DISPLAY_TAXED_PRICE={appConfig.DISPLAY_TAXED_PRICE}
          from={from}
          volumeDiscountData={volumeDiscountData}
        />
      ))}
    </>
  );
})
const CartItemCounter = React.memo(({ isLoggedIn, from, data, isSaveForLaterItemsLoading, isWalletCreditWithPurchaseApplicable }) => {
  const { updateCartItem, deleteCartItem } = useModifyCart();
  const { addToSaveForLater } = useAPI();
  const [localLoader, setLocalLoader] = useState(false)
  const dispatchRedux = useDispatch()

  const updatingQuantity = useSelector(state => state.cart?.updatingProductsList?.includes(data.bundle_id || data.product_details_id))
  const addToCartLoader = useSelector(state => state.cart?.addToCartLoader) && updatingQuantity
  const notificationsOverlap = useSelector(state => state.modal.notificationsOverlap)
  const itemsInCart = useSelector(state => state.cart?.cartData?.cart_items)
  const tempCart = useSelector(state => state.cart?.tempCart) || []
  const refreshCart = useSelector(state => state.cart?.refreshCart)

  const { amount = 0 } = useSelector(state => state.cart?.cartData)
  const [quantity, setQuantity] = useState(data.quantity)
  const [isCartLocked, setCartLocked] = useState(false);
  const maxQuantity = data.product_max_quantity;
  useEffect(() => {
    if (!updatingQuantity) setLocalLoader(false)
  }, [updatingQuantity])
  useEffect(() => {
    if (notificationsOverlap?.[0]?.type === 'confirmProductRemovalPopup') {
      setLocalLoader(false)
    }
  }, [notificationsOverlap])
  const handleBulkProductPrice = useCallback((product) => {
    const cartItem = product;
    if (!isLoggedIn && cartItem.bulk_prices) {
      const index = findIndex(cartItem.bulk_prices, { quantity: cartItem.quantity });
      if (index === -1) {
        for (let i = cartItem.quantity; i > 0; i -= 1) {
          const location = findIndex(cartItem.bulk_prices, { quantity: i });
          if (location !== -1) {
            cartItem.price = cartItem.bulk_prices[location].price;
            break;
          }
        }
      } else {
        cartItem.price = cartItem.bulk_prices[index].price;
      }
    }
    return cartItem;

  }, [isLoggedIn])
  function handleQuantityChange(cartItem, quantity) {
    setLocalLoader(true)
    if (isLoggedIn) {
      dispatchRedux(setAddingToCart(true))
    }

    switch (quantity) {
      case 0: {
        let walletWithPurchaseProps = null;

        if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
          walletWithPurchaseProps = {
            currentProductDetails: cartItem || {},
            cartSubtotal: amount,
            dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
            requestedProductQuantity: 0,
            onRemove: () =>
              deleteCartItem({
                product: cartItem,
                immediate: true,
                type: from
              })
          };
        }

        deleteCartItem({ product: cartItem, immediate: true, type: from, walletWithPurchaseProps });
        break;
      }

      default: {
        const product = handleBulkProductPrice({ ...cartItem, quantity });

        let walletWithPurchaseProps = null;

        if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
          walletWithPurchaseProps = {
            currentProductDetails: cartItem || {},
            cartSubtotal: amount,
            dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
            requestedProductQuantity: product.quantity,
            onRemove: () =>
              updateCartItem({
                product,
                force: true,
                type: from
              })
          };
        }

        updateCartItem({ product, force: true, type: from, walletWithPurchaseProps });

        break;
      }
    }
  }

  async function handleDelete() {
    handleQuantityChange(data, 0);
  }

  function handleIncreaseQuantity() {
    isLoggedIn ?
      handleQuantityChange(data, quantity + 1)
      : handleQuantityChange(data, Number(data.quantity) + 1);
  }

  async function handleDecreaseQuantity() {
    isLoggedIn ? handleQuantityChange(data, quantity - 1) : handleQuantityChange(data, Number(data.quantity) - 1);
  }
  const saveForLater = () => {
    if (!isLoggedIn) {
      handleQuantityChange(data, 0);
    }
    addToSaveForLater({ items: [data], type: from });
  };
  const quantityRef = useRef(0)

  useEffect(() => {
    let temp, tempTwo
    if (data.bundle_id) {
      temp = itemsInCart?.find(item => item.bundle_id == data?.bundle_id)
      tempTwo = tempCart.find(item => item.product_id === data.product_id)

    } else {
      temp = itemsInCart?.find(item => item.product_details_id == data?.product_details_id)
      tempTwo = tempCart.find(item => item.product_id === data.product_id)

    }

    if (tempTwo?.quantity) {
      quantityRef.current = tempTwo?.quantity
      !localLoader && setQuantity(tempTwo?.quantity)
    } else if (quantityRef.current === (temp?.quantity || 0) || (!tempTwo?.quantity && !temp?.quantity)) {
      quantityRef.current = null
    }

    (!quantityRef.current) && setQuantity(tempTwo?.quantity || temp?.quantity || 0)
  }, [itemsInCart, tempCart, updatingQuantity, localLoader])

  useEffect(() => {
    if (isLoggedIn && refreshCart == data.product_details_id || refreshCart === data.bundle_id) {
      let temp
      if (data.bundle_id) {
        temp = itemsInCart?.find(item => item.bundle_id == data?.bundle_id)
      }
      else {
        temp = itemsInCart?.find(item => item.product_details_id == data?.product_details_id)
      }
      !localLoader && setQuantity(temp?.quantity || 0)
    }
  }, [refreshCart, itemsInCart, isLoggedIn, localLoader])

  useEffect(() => {
    const { lockCart = false } = storageService.getInitialWebviewData();
    setCartLocked(lockCart);
  }, []);
  return <div className="text-center align-self-end mt-2 mb-1 d-flex flex-column">
    <div className="count-holder text-right align-self-end d-flex">
      <>
        {!isCartLocked ? (
          <>
            {data.quantity == 1 ? (
              <button
                disabled={localLoader || addToCartLoader || updatingQuantity || (isLoggedIn && !data.cart_id)}
                type="button"
                className="btn update-count-btn align-middle delBtn"
                onClick={handleDelete}
              >
                <span className="icon-trash-alt2 delete-btn" />
              </button>
            ) : (
              <button
                disabled={updatingQuantity || (isLoggedIn && !data.cart_id)}
                type="button"
                className="btn update-count-btn align-middle"
                onClick={handleDecreaseQuantity}
              >
                <span>
                  <i className="icon-minus count-btn bold" />
                </span>
              </button>
            )}
          </>
        ) : null}

        {localLoader || (updatingQuantity && addToCartLoader) ? (
          <InlineLoader positionRelative className="px-3 mb-0 auto-width a-loader cart-widget-loader checkout-loader" text={quantity} />
        ) : (
          <span className="count d-inline-block bold align-middle">{quantity}</span>
        )}

        {!isCartLocked ? (
          <button
            disabled={
              localLoader ||
              data.quantity >= maxQuantity ||
              data.is_pre_deal_available ||
              updatingQuantity ||
              (isLoggedIn && !data.cart_id)
            }
            type="button"
            className="btn update-count-btn align-middle pr-0"
            onClick={handleIncreaseQuantity}
          >
            <span>
              <i className="icon-plus count-btn bold" />
            </span>
          </button>
        ) : null}
      </>
    </div>

    <div>
      {isWishlistApplicable ? (
        <div className="mt-1">
          <a
            className={`pointer save-for-later-link ${isSaveForLaterItemsLoading ? 'no-pointer-events-rr' : ''}`}
            onClick={saveForLater}
          >
            Save for Later
          </a>
        </div>
      ) : null}
    </div>
  </div>
})
export { CartWidgetItems as default, Item };
